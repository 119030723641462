import React from 'react'
import './services.css'
import {AiOutlineCheck} from 'react-icons/ai'
const services = () => {
  return (
    <section id='services'>
        <h5> what I offer</h5>
        <h2>Services</h2>
      <div className="container services__container">
        <article className='service'>
            <div className="service__head">
                <h3> UI/UX Design</h3>
            </div>
            <ul className='service__list'>
                <li>
                    <AiOutlineCheck className='service__list-icon' />
                <p>comming soon!! wait is over</p>
                </li>
                <li>
                    <AiOutlineCheck className='service__list-icon' />
                <p>comming soon!! wait is over</p>
                </li>
                <li>
                    <AiOutlineCheck className='service__list-icon' />
                <p>comming soon!! wait is over</p>
                </li>
                <li>
                    <AiOutlineCheck className='service__list-icon' />
                <p>comming soon!! wait is over</p>
                </li>
                <li>
                    <AiOutlineCheck className='service__list-icon' />
                <p>comming soon!! wait is over</p>
                </li>
            </ul>

        </article>
        <article className='service'>
            <div className="service__head">
                <h3> Web Development</h3>
            </div>
            <ul className='service__list'>
                <li>
                    <AiOutlineCheck className='service__list-icon' />
                <p>comming soon!! wait is over</p>
                </li>
                <li>
                    <AiOutlineCheck className='service__list-icon' />
                <p>comming soon!! wait is over</p>
                </li>
                <li>
                    <AiOutlineCheck className='service__list-icon' />
                <p>comming soon!! wait is over</p>
                </li>
                <li>
                    <AiOutlineCheck className='service__list-icon' />
                <p>comming soon!! wait is over</p>
                </li>
                <li>
                    <AiOutlineCheck className='service__list-icon' />
                <p>comming soon!! wait is over</p>
                </li>
            </ul>

        </article>
        <article className='service'>
            <div className="service__head">
                <h3> Contain Creation</h3>
            </div>
            <ul className='service__list'>
                <li>
                    <AiOutlineCheck className='service__list-icon' />
                <p>comming soon!! wait is over</p>
                </li>
                <li>
                    <AiOutlineCheck className='service__list-icon' />
                <p>comming soon!! wait is over</p>
                </li>
                <li>
                    <AiOutlineCheck className='service__list-icon' />
                <p>comming soon!! wait is over</p>
                </li>
                <li>
                    <AiOutlineCheck className='service__list-icon' />
                <p>comming soon!! wait is over</p>
                </li>
                <li>
                    <AiOutlineCheck className='service__list-icon' />
                <p>comming soon!! wait is over</p>
                </li>
            </ul>

        </article>
      </div>



    </section>
  )
}

export default services