import React from "react"
import './portfolio.css'
import IMG1 from "../../assets/portfolio1.jpg";
import IMG2 from "../../assets/portfolio2.jpg";
import IMG3 from "../../assets/portfolio3.jpg";
import IMG4 from "../../assets/portfolio4.jpg";
import IMG5 from "../../assets/portfolio5.png";

import IMG6 from "../../assets/portfolio6.jpg";
const data =[
  {
    id: 1,
    image: IMG1,
    title: 'Visualization of data on the planet for global data',
    Github:'http://github.com',
    demo: 'https://dribbble.com/shots/21345668-Visualization-of-data-on-the-planet-for-global-data',

  },
  {
    id: 2,
    image: IMG2,
    title: 'Eclipse - Figma dashboard UI kit for data design web apps',
    Github:'http://github.com',
    demo: 'https://dribbble.com/shots/21345640-Eclipse-Figma-dashboard-UI-kit-for-data-design-web-apps',

  },
  {
    id: 3,
    image: IMG3,
    title: 'Orion UI kit – data visualization and charts templates for Figma',
    Github:'http://github.com',
    demo: 'https://dribbble.com/shots/21336426-Orion-UI-kit-data-visualization-and-charts-templates-for-Figma',

  },
  {
    id: 4,
    image: IMG4,
    title: 'Eclipse - Figma dashboard UI kit for data design web apps',
    Github:'http://github.com',
    demo: 'https://dribbble.com/shots/21277705-Eclipse-Figma-dashboard-UI-kit-for-data-design-web-apps',

  },
  {
    id: 5,
    image: IMG5,
    title: 'Orion UI kit – data visualization and charts templates for Figma',
    Github:'http://github.com',
    demo: 'https://dribbble.com/shots/21277611-Orion-UI-kit-data-visualization-and-charts-templates-for-Figma',

  },
  {
    id: 6,
    image: IMG6,
    title: 'Eclipse - Figma dashboard UI kit for data design web apps',
    Github:'http://github.com',
    demo: 'https://dribbble.com/shots/21277585-Eclipse-Figma-dashboard-UI-kit-for-data-design-web-apps',

  },
]
const portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Resent Work</h5>
      <h2>Portfolio</h2>
      <div className=" container portfolio__container">
       {
        data.map(({id,image,title,github,demo })=>{
          return(
            <article key={id}className="portfolio__item">
            <div className="portfolio__item-image">
              <img src={image} alt="" />
            </div>
            <h3>{title}</h3>
            <div className="portfolio__item-cta">
              <a href={github} className="btn" target="_blank">
                github
              </a>
              <a href={demo} className="btn btn-primary" target="_blank">
          Live demo
              </a>
            </div>
          </article>
          )
        }

        )
       }
  
      </div>
    </section>
  );
};

export default portfolio;
