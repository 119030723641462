import React from "react";
import "./about.css";
import Me from "../../assets/me-about.jpeg";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";

const About = () => {
  return (
    <section id="about">
      <h5>Get to Know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={Me} alt="About Image" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>2+years working </small>
            </article>
            <article className="about__card">
              <FiUsers className="about__icon" />
              <h5>Clients</h5>
              <small>Increasing in number of clients</small>
            </article>
            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>Done some projects</small>
            </article>
          </div>
          <p>
            Undergoing a computer science degree from Tribhuwan University and
            an experience of about 1 and a half years in coding and teaching
            sector' I have developed coding skills and how to intract in mass of
            people. I'm an enthusiastic individual seeking a mid-level position
            where I can showcase and use my developed skills to develop
            different types of web and Android applications.
          </p>
          <a href="#contact" className="btn btn-primary">
            let's talk</a>
        </div>
      </div>
    </section>
  )
}

export default About;
