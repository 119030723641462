import React from 'react'
import {FaLinkedin} from 'react-icons/fa'
import {FaGithub} from 'react-icons/fa'
import {FaFacebook} from 'react-icons/fa'
const Headersocials = () => {
  return (
 <div className='header__socials'>
    <a href='http://linkedin.com' target='_blank'><FaLinkedin/></a>
    <a href='http://github.com' target='_blank'><FaGithub/></a>
    <a href='http://facebook.com' target='_blank'><FaFacebook/></a>
 
 </div>
  )
}

export default Headersocials