import React from 'react'
import "./contact.css"
import{MdOutlineEmail} from 'react-icons/md'
import{TbMessageCircle} from 'react-icons/tb'
import{BsWhatsapp} from 'react-icons/bs'
import{ useRef } from 'react';
import emailjs from 'emailjs-com'
const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_sf3aojs', 'template_fmih9pj', form.current, 'ZcfrYzQ6TNm9OJRFV')
  };
  return (
   <section id='contact'>
    <h5>Get In Touch</h5>
    <h2>CONTACT ME</h2>
    <div className="container contact__container">
      <div className="contact__options">
        
        <article className='contact__option'>
          <MdOutlineEmail/>
          <h4>Email</h4>
          <h5>anandadahal37@gmail.com</h5>
          <a href='mailto:anandadahal37@gmail.com'target='_blank'>sent a messagae</a>

        </article>
        <article className='contact__option'>
          <TbMessageCircle/>
          <h4>Messanger</h4>
          <h5>Ananda dahal</h5>
          <a href='https://m.me/prajal.dahal.31'target='_blank'>Send me message</a>

        </article>
        <article className='contact__option'>
         <BsWhatsapp/>
          <h4>Whatsapp</h4>
          <h5>9805384291</h5>
          <h5>anandadahal37@gmail.com</h5>
          <a href='http://api.whatsapp.com/sent?phone+9779805384291' target='_blank'>sent a messagae</a>

        </article>

      </div>
      
      <form ref={form} onSubmit={sendEmail}>
        <input type="text"name='name '  placeholder='Your full name'/>
        <input type="text" name="Email" placeholder='Your Email' />
        <textarea name="Message" rows='7' placeholder='your Message' required></textarea>
        <button type='submit' className='btn btn-primary'>Send Message</button>

      </form>
    </div>
   </section>
  )
}

export default Contact