import React from 'react'
import "./footer.css"
import {FaFacebookF} from 'react-icons/fa'

import {FiInstagram} from 'react-icons/fi'
import {IoLogoTwitter} from 'react-icons/io'
const footer = () => {
  return (
    <footer>
    <a href='#' className='footer__logo'>Ananda</a>
   <ul className='permalinks'>
    <li><a href='#'>Home</a></li>
    <li><a href='#about'>About</a></li>
    <li><a href='#experience'>Experience</a></li>
    <li><a href='#services'>Servics</a></li>
    <li><a href='#portfolio'>Portfolio</a></li>
    <li><a href='#contact'>Contact</a></li>
    
   </ul>
   <div className="footer__socials">
    <a href='https://facebook.com'><FaFacebookF/></a>
    <a href='https://instagram.com'><FiInstagram/></a>
    <a href='htttps://twiter'><IoLogoTwitter/></a>
   </div>
   <div className="footer__copyright">
    <small> &copy; All rights reserved. </small>
   </div>
   </footer>
  )
}

export default footer