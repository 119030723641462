import React from 'react'
import Header from './compontent/header/Header'
import Nav from './compontent/nav/nav'
import About from './compontent/about/About'
import Experience from './compontent/experience/experience'
import Services from './compontent/services/services'
import Portfolio from './compontent/portfolio/portfolio'
import Contact from './compontent/contact/contact'
import Footer from './compontent/footer/footer'


const App = () => {
  return (
    <>
    <Header/>
    <Nav/>
    <About/>
    <Experience/>
    <Services/>
    <Portfolio/>
    <Contact/>
    <Footer/>
   
   

    </>
  )
}

export default App