import React from 'react';
import "./header.css"
import CTA from './CTA';
import ME from '../../assets/me.png'
import Headersocial from './Headersocials'; 

const Header = () => {
  return (
  <header>
    <div className='container header__container'>
      <h5>
        hello I'm
      </h5>
      <h1>
        Ananda Dahal
      </h1>
      <h5 className='text-light'>Front-end Developer

      </h5>
      <CTA/>
      <Headersocial/>
     
   
     
      <div className='me'>
        <img src={ME} alt="me"/>
        
      </div>
      <div>
      <a href='#contact' className='scroll__down'>scroll down</a>
      </div>


    </div>
  </header>
  )
}

export default Header;